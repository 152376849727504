<template>
    <div>
        <!-- begin::Entry -->
        <div class="d-flex flex-column flex-row-fluid">
            <!--begin::Container-->
            <div class="container-fluid py-8">
                <!--begin::Education-->
                <div class="d-flex flex-row">
                    <UserNav activePage="adminfeed" activeTab="2"></UserNav>
                    <!--begin::Content-->
                    <div class="flex-row-fluid ml-lg-8">
                        <div class="row">
                            <div class="col-xxl-6" v-if="coachFeed.length > 0">
                                <!-- <button class="btn btn-primary font-weight-bolder font-size-sm py-3 px-9"
                                        id="kt_app_education_more_feeds_btn"  @click="vectorizeCoachList">Vectorize</button> -->
                                <FeedBox :coachFeed=coachFeed v-bind:showMore=true v-bind:dashboard="true" />
                                <div class="d-flex flex-center pb-1" v-if="moreAllFeedPending">
                                    <button class="btn btn-primary font-weight-bolder font-size-sm py-3 px-9"
                                        id="kt_app_education_more_feeds_btn" @click="loadMore">More Topics</button>
                                </div>
                            </div>
                            <div class="col-xxl-6" v-else>
                                <div class="card card-custom gutter-b h300">
                                    <!--begin::Body-->
                                    <div class="card-body pt-0">
                                        <!--begin::Scroll-->
                                        <div>
                                            <!--begin::Messages-->
                                            <div class="messages">
                                                <!--begin::Message In-->
                                                <h5>
                                                    <div class="text-center center">
                                                        <p>
                                                            There are no feeds yet!
                                                        </p>
                                                        <p class="pt-5">
                                                            <i class="fa fa-video-slash icon-2x"></i>
                                                        </p>
                                                    </div>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-6">
                                <div class="card card-custom gutter-b">
                                    <!--begin::Header-->
                                    <div class="card-header border-0">
                                        <h3 class="card-title font-weight-bolder text-dark">Sessions</h3>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-0">
                                        <b-table id="sessions-table" :items="dashboardProgramSessions" :fields="fields"
                                            responsive="sm" :head-variant="headVariant" :per-page="perPage"
                                            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                            :sort-compare="mySortCompare" show-empty>

                                            <template #cell(datetime)="data">
                                                <router-link
                                                    :to="{ name: 'feeddetail', params: { feedId: data.item.id, sessionId: data.item.sessionid, coachlistId: data.item.coachlistid } }">
                                                    {{ shortFormatDate(data.item.datetime) }}
                                                </router-link>
                                            </template>
                                            <template #cell(title)="data">
                                                <router-link
                                                    :to="{ name: 'feeddetail', params: { feedId: data.item.id, sessionId: data.item.sessionid, coachlistId: data.item.coachlistid } }"
                                                    class="text-dark">
                                                    <span class="fssmaller">{{ data.item.coach.first }} {{  data.item.coach.last }}</span>
                                                    <br>
                                                    {{ data.item.title }}
                                                </router-link>
                                            </template>
                                            <template #cell(status)="data">
                                                <div class="text-center">
                                                    <span
                                                        class="label label-lg label-light-success label-inline font-weight-bold py-4 mr-5"
                                                        v-if="data.item.online">Online</span>
                                                    <span
                                                        class="label label-lg label-light-danger label-inline font-weight-bold py-4 mr-5"
                                                        v-else>Offline</span>
                                                </div>
                                            </template>
                                            <template #cell(messageCount)="data">
                                                <div class="text-center">
                                                    {{ data.item.messageCount }}
                                                </div>
                                            </template>
                                        </b-table>
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                            aria-controls="sessions-table"></b-pagination>
                                    </div>
                                    <!--end: Card Body-->
                                </div>
                                <!--end: Card-->

                            </div>
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Education-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import loadMoreMixin from "@/mixins/loadMore"
import UserNav from "@/view/layout/header/UserNav.vue";
import FeedBox from "@/view/pages/feed/Feed.vue";
import formateDateMixin from "@/mixins/formatDate"
import * as fb from '../../../api/firebase.js';

export default {
    name: "adminfeed",
    async mounted() {
        this.$store.dispatch("getCurrentCoachDetail")
        let user = await fb.firebase.getCurrentUser()
        fb.usersCollection.where("user_id", "==", user.uid).onSnapshot((snapshot) => {
            if (snapshot.docs.length > 0) {
                snapshot.docs.map((doc) => {
                    let user = doc.data()
                    this.currentUser = user
                    this.$store.dispatch("getAllFeedPagination", {
                            limit: 20
                        })
                        this.$store.dispatch("getDashboardProgramSessionsNoId")
                })
            }
        }, (error) => { })
    },
    components: {
        UserNav,
        FeedBox
    },
    mixins: [formateDateMixin, loadMoreMixin],
    data() {
        return {
            refresh: false,
            perPage: 5,
            currentPage: 1,
            sortBy: 'datetime',
            sortDesc: true,
            headVariant: "light",
            sessionid: "",
            fields: [
                { key: "datetime", label: "Date", sortable: true },
                { key: "title", label: "Name/Title" },
                { key: "status", label: "Status" },
                { key: "messageCount", label: "Messages", sortable: true },
            ],
            currentUser: []
        }
    },
    computed: {
        ...mapGetters({
            coachList: "programCoachDetail",
            dashboardProgramSessions: "dashboardProgramSessions",
            coachFeed: "allFeed",
            moreAllFeedPending: "moreAllAllFeedPending",
            chatHistory: "chatHistory",
            videoIssues: "videoIssues"
        }),
        rows() {
            return this.dashboardProgramSessions.length
        }
    },
    methods: {
        mySortCompare(a, b, key) {
            if (key === 'datetime') {
                // Assuming the date field is a `Date` object, subtraction
                // works on the date serial number (epoch value)
                return a[key] - b[key]
            } else {
                // Let b-table handle sorting other fields (other than `date` field)
                return false
            }
        },
        loadMore() {
            if (
                this.moreAllFeedPending &&
                this.coachFeed.length > 0
            ) {
                this.$store.dispatch("getAllFeedPagination", {
                        limit: 5
                    });
            }
        },
        reloadSessionsList() {
            this.refresh = true
            setTimeout(() => (this.$store.dispatch("resetAllAllFeed")), 2500)
            // this.$store.dispatch("resetAllSessions")
        },
        vectorizeCoachList() {
            this.$store.dispatch("updateVectorCoachListAll", { });
        }
    },
    watch: {
        coachFeed(newValue) {
            this.refresh = false
        }
    }
}
</script>

<style scoped>
.h300 {
    height: 300px;
    overflow: scroll;
}

.center {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.fs-15 {
    font-size: 1.5rem !important;
}

.p-025 {
    padding: 0 25px;
}

.hw60100 {
    height: 60px !important;
    width: auto !important;
}

.hw60 {
    height: 60px;
    width: 60px;
}

.sessionimage {
    object-fit: contain;
    margin: 0 auto;
}

.symbol.symbol-light .symbol-label {
    background-color: white;
}

.symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.fssmaller {
    font-size: 12px;
    font-weight: bold;
}
</style>
